import { getLocationDetails } from '@lib/prepr';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { getCookie, setCookie } from './clientCookies';
import formatArrayIntoComma from './formatArrayIntoComma';
import getBase64 from './getBase64';
import getRandomInt from './getRandomInt';
import scrollTo from './scrollTo';
import useForm from './useForm';
import useStore from './useStore';

export default function useApplicationForm({
  vacancyCode: initVacanyCode,
  aggregationChildren = [],
  scrollToRef,
  applicationVacanciesRef,
}) {
  const {
    store: { host },
  } = useStore();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { query, ...router } = useRouter();
  const [stage, setStage] = useState();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [vacancyApplicationError, setVacancyApplicationError] = useState();
  const [succeededApplications, setSucceededApplications] = useState([]);
  const progressBarInterval = useRef();
  const { inputs, handleChange } = useForm({
    In: '', // initials
    CaNm: '', // firstname
    Is: '', // prefix
    LaNm: '', // lastname
    ViGe: '', // gender
    CoId: '', // country
    Ad: '', // street
    HmNr: '', // housenumber
    HmAd: '', // toevoeging
    ZpCd: '', // zipcode
    Rs: '', // city
    Mo: '', // motivation
    ViSo: '', // information source
    DaBi: '', // date of birth
    MbN2: '', // mobile phone
    EmA2: '', // work email
    U5F8BEDE64600DC34619ECCBBBF160222: '', // BIG registration
    U8E0273EE46440ED8292A7A966F37F5CC: '', // Hours per week
    U9FA557F74D947F024E0C6BB834579EC7: '', // Price
    UEE25D364416ABB14856253BE59291158: '', // Ben je op de speeddate geweest?
    OpEm: false, // Optin for data to be saved
    selectedVacancies: [],
  });

  /**
   * This section of code is responsible for handling the unique vacancies in the application form.
   * These are used to show in the application form as checkboxes along with location details.
   * It iterates through the `aggregationChildren` array and filters out duplicate vacancies based on location and hours.
   * The unique vacancies are stored in the `uniqueVacancies` array.
   */
  const uniqueVacancies = [];
  const vacancyMap = new Map();
  aggregationChildren.forEach(child => {
    const { locationCode, minHours, maxHours } = child;
    const key = `${locationCode}-${minHours}-${maxHours}`;

    if (!vacancyMap.has(key)) {
      const vacancy = {
        ...child,
        vacanciesCount: 1,
      };
      uniqueVacancies.push(vacancy);
      vacancyMap.set(key, vacancy);
    } else {
      const vacancy = vacancyMap.get(key);
      vacancy.vacanciesCount += 1;
      vacancy.vacancyCode = `${vacancy.vacancyCode},${child.vacancyCode}`;
    }
  });
  const [vacancies, setVacancies] = useState(uniqueVacancies);

  // Fake upload progress bar animation
  const startProgressBar = init => {
    let prev = init;
    progressBarInterval.current = setInterval(() => {
      prev = prev < 80 ? (prev += getRandomInt({ min: 1, max: 3 })) : prev;
      setProgress(prev);
    }, 1000);
  };

  const resetProgressBarInterval = () => {
    clearInterval(progressBarInterval.current);
  };

  const clearState = () => {
    setLoading(false);
    setStage();
    resetProgressBarInterval();
  };

  const handleSuccess = () => {
    // Clear the progress bar interval
    resetProgressBarInterval();
    setProgress(100);
    setStage('Klaar, u wordt doorgestuurd naar de bedanktpagina');
    // minor timeout so the user can read the message above
    setTimeout(() => {
      const href = query.id
        ? `/vacatures/${query.slug}/${query.id}/bedankt-voor-je-sollicitatie`
        : `/vacatures/${query.slug}/bedankt-voor-je-sollicitatie`;
      router.push(href);
    }, 1000);
  };

  const handleError = ({ currentFailedApplications, errorMessage }) => {
    clearState();
    // Check if we multiple applications selected
    if (inputs.selectedVacancies.length > 1) {
      // Get the location from each failed application
      const failedApplicationLocations = aggregationChildren
        .filter(({ vacancyCode }) =>
          currentFailedApplications.includes(`${vacancyCode}`)
        )
        .map(
          ({ locationTitle, minHours, maxHours }) =>
            `"${locationTitle} ${minHours} - ${maxHours} uur"`
        );
      // Show an error message containing the failed locations
      setError({
        message: `Er is iets fout gegaan bij het versturen van je sollicitatie op ${formatArrayIntoComma(
          failedApplicationLocations
        )}${
          errorMessage ? `: ${errorMessage}` : '.'
        } Probeer het later opnieuw.`,
      });
    } else {
      // Generic error message for only 1 failed application
      setError({
        message:
          errorMessage ||
          `Er is iets fout gegaan bij het versturen van je sollicitatie`,
      });
    }
    setProgress(0);
  };

  // Check if atleast 1 application vacancy is selected,
  // if that is not the case we should show an error
  const applicationVacanciesAreValid = () => {
    if (
      vacancies.length <= 1 ||
      (vacancies.length && inputs.selectedVacancies?.length > 0)
    )
      return true;

    setVacancyApplicationError(true);
    scrollTo({ scrollToRef: applicationVacanciesRef });
    return false;
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (loading) return;

    if (!applicationVacanciesAreValid()) {
      return;
    }
    // Check if the validation func exists
    if (!executeRecaptcha) {
      setError({
        message: 'Recaptcha kan niet worden geladen, probeer het opnieuw.',
      });
      return;
    }

    setLoading(true);
    scrollTo({ scrollToRef });
    setError();
    setVacancyApplicationError(false);

    // Start the first stage of loading the document.
    setStage('Cv verwerken');
    setProgress(10);

    let document;
    if (inputs.file) {
      document = await getBase64(inputs.file).catch(error => {
        console.error(error);
        setError({
          message:
            'Er is iets fout gegaan bij het laden van het bestand, probeer het opnieuw toe te voegen.',
        });
        clearState();
      });
    }
    // The stage for loading the document is done,
    // now starting the stage to post the application(s)
    setStage(
      `${
        inputs.selectedVacancies?.length > 1
          ? 'Sollicitaties versturen'
          : 'Sollicitatie versturen'
      }`
    );
    startProgressBar(25);

    // Keep track of the failed and succeeded applications
    let currentFailedApplications = [];
    let currentSucceededApplications = [];
    let errorMessage = '';

    // Get the vacancy codes from the selected vacancies
    let vacancyCodes = inputs.selectedVacancies
      .flatMap(vacancyCode => vacancyCode.split(','))
      // Filter out the already succeeded applications for when the user retries to submit the application
      .filter(vacancyCode => !succeededApplications.includes(vacancyCode));

    if (!vacancyCodes.length) {
      vacancyCodes = [initVacanyCode];
      if (succeededApplications.includes(initVacanyCode)) {
        // We already applied to this vacancy
        handleSuccess();
        return;
      }
    }

    const handlePostApplication = async vacancyCode => {
      const token = await executeRecaptcha();
      await axios
        .post(`/api/apply-to-vacancy`, {
          token,
          path: router.asPath,
          HrCreateApplicant: {
            Element: {
              Fields: {
                ...inputs,
                VcSn: vacancyCode, // AFAS vacancyId
                EmAd: inputs.EmA2, // Copy the private mail to workmail, this is required for afas
                CvFileStream: document?.base64String,
                CvFileName: document?.fileName,
              },
            },
          },
        })
        .then(() => {
          // Keep track of the succeeded applications
          currentSucceededApplications = [
            ...currentSucceededApplications,
            vacancyCode,
          ];
        })
        .catch(err => {
          console.error(err);
          if (err?.response?.data?.error?.message) {
            errorMessage = err?.response?.data?.error?.message;
          }
          // Keep track of the failed applications
          currentFailedApplications = [
            ...currentFailedApplications,
            vacancyCode,
          ];
        });
    };

    // Post the first application this is needed for AFAS to create the applicant
    // and connect the applicant data to the other applications.
    await handlePostApplication(vacancyCodes[0]);
    // Return if the first application fails
    if (currentFailedApplications.length) {
      handleError({ currentFailedApplications, errorMessage });
      return;
    }
    // If there is only 1 application location, we can skip the rest of the process
    if (vacancyCodes.length === 1) {
      setCookie(
        'applied-to-vacancies',
        JSON.stringify([
          ...succeededApplications,
          ...currentSucceededApplications,
        ])
      );
      handleSuccess();
      return;
    }

    const restOfVacancyCodes = vacancyCodes.slice(1);

    setTimeout(async () => {
      // Start the application process for the rest of the application locations
      // Promise.allSettled waits for all the fetches to be done
      await Promise.allSettled([
        ...restOfVacancyCodes.map(async vacancyCode =>
          handlePostApplication(vacancyCode)
        ),
      ]).then(async () => {
        // Promise.allSettled only exposes `then` function,
        // We use this to handle either the success or fail responses
        setSucceededApplications([
          ...succeededApplications,
          ...currentSucceededApplications,
        ]);
        setCookie(
          'applied-to-vacancies',
          JSON.stringify([
            ...succeededApplications,
            ...currentSucceededApplications,
          ])
        );

        if (currentFailedApplications.length) {
          return handleError(currentFailedApplications);
        }
        return handleSuccess();
      });
    }, 2000);
  };

  // Clean interval on unmount
  useEffect(() => () => clearInterval(progressBarInterval.current), []);

  useEffect(() => {
    // Return if there are no multiple locations for this application
    if (!vacancies?.length) return;

    // Synchronous function to fetch details for each unique location code.
    const getLocationsDetails = async () => {
      const uniqueLocationCodes = [
        ...new Set(aggregationChildren.map(child => child.locationCode)),
      ];
      let locationDetails = {};
      // Use Promise.allSettled to concurrently fetch details for each unique location code.
      await Promise.allSettled(
        uniqueLocationCodes.map(locationCode =>
          getLocationDetails({ host, afasCode: locationCode })
        )
      ).then(
        // Filter out the failed requests and return the value of the fulfilled requests.
        responses =>
          responses
            .filter(
              ({ status, value }) => status === 'fulfilled' && value?.items[0]
            )
            .forEach(({ value }) => {
              locationDetails = {
                ...locationDetails,
                [value.items[0].afasCode]: { ...value.items[0] },
              };
            })
      );

      // Iterate through unique location codes and assign corresponding details from results.
      // Set the state with the obtained location details.
      setVacancies(
        vacancies.map(location => {
          const value = locationDetails[location.locationCode] || {};
          return {
            ...location,
            ...value,
            id: location.id,
            hasDetails: !!Object.keys(value).length,
          };
        })
      );
    };

    getLocationsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get which vacancies the user has already applied to
    const vacancyCodes = getCookie('applied-to-vacancies') || '[]';
    setSucceededApplications(JSON.parse(vacancyCodes));
  }, []);

  return {
    inputs,
    handleChange,
    onSubmit,
    error,
    loading,
    stage,
    progress,
    vacancies,
    vacancyApplicationError,
    succeededApplications,
  };
}
